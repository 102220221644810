import { OrgId, Org, OrgSettings } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import { isProduction } from "./env-helpers";
import _ from "lodash";
import { common__hashObject } from "../api/common.api";
import { htmlify } from "./linkify";

export function forceHttps(url: string): string {
  return "https://" + url.replace(/https?:\/\//i, "");
}

export const whiteList = ["nategreat13@hotmail.com", "gyginathan@gmail.com"];

export function isEmailWhitelisted(email: string) {
  return whiteList.includes(email);
}

export function shouldSendEmail(email: string) {
  // Never send emails to ollie-testing.com emails
  if (email.includes("ollie-testing.com")) {
    return false;
  }
  // Only send if email is whitelisted or we are in prod
  if (isEmailWhitelisted(email) || isProduction()) {
    return true;
  }
  return false;
}

export interface OrgEmailRequiredPersonalizations {
  email: string;
  name: string;
  subject: string;
  message: string;
}

interface OrgEmailCompletePersonalization {
  to: string;
  dynamicTemplateData: {
    subject: string;
    name: string;
    logoUrl?: string;
    facebookUrl?: string;
    instagramUrl?: string;
    twitterUrl?: string;
    message: string;
    logoLinkUrl?: string;
  };
}

export async function sendOrgEmail(p: {
  orgId: OrgId;
  org?: Org;
  orgSettings?: OrgSettings;
  personalizations: OrgEmailRequiredPersonalizations[];
  replyToEmailAddress?: string;
  replyToName?: string;
}) {
  const { injectedServerLibraries, appOllieFirestoreV2: h } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const sendGrid = injectedServerLibraries.sendGrid;

  const [org, orgSettings] = await Promise.all([
    p.org ?? (await h.Org.getDoc(p.orgId)),
    p.orgSettings ?? (await h.OrgSettings.getDoc(p.orgId))
  ]);

  if (!org) {
    throw new Error("Cannot find the Org: " + p.orgId);
  }
  if (!orgSettings) {
    throw new Error("Cannot find the Org Settings: " + p.orgId);
  }

  const personalizations: OrgEmailCompletePersonalization[] = p.personalizations.map(personalization => {
    return {
      to: personalization.email.replace(/\s/g, ""),
      dynamicTemplateData: {
        name: personalization.name,
        subject: personalization.subject,
        message: htmlify(personalization.message),
        facebookUrl: orgSettings?.socialUrls?.facebookUrl ? forceHttps(orgSettings.socialUrls.facebookUrl) : undefined,
        instagramUrl: orgSettings?.socialUrls?.instagramUrl ? forceHttps(orgSettings.socialUrls.instagramUrl) : undefined,
        twitterUrl: orgSettings?.socialUrls?.twitterUrl ? forceHttps(orgSettings.socialUrls.twitterUrl) : undefined,
        logoLinkUrl: orgSettings?.websiteUrl ? forceHttps(orgSettings?.websiteUrl) : undefined,
        logoUrl: org?.logoUri
      },
      mailSettings: {
        bypassListManagement: {
          enable: true
        }
      }
    };
  });

  let emailDataFiltered = isProduction() ? personalizations : personalizations.filter(info => shouldSendEmail(info.to));
  emailDataFiltered = _.uniqBy(emailDataFiltered, a => common__hashObject({ obj: a.dynamicTemplateData }) + a.to.toLowerCase());

  if (emailDataFiltered.length) {
    //Start incredibly ugly stuff to fix this Sendgrid SDK limitation (https://github.com/sendgrid/opensource/issues/23)
    const personalizationsPerUniqueEmail = _(emailDataFiltered)
      .groupBy(a => a.to.toLowerCase())
      .values()
      .value();

    const pass1Chunks: OrgEmailCompletePersonalization[][] = [];
    for (let i = 0; i < personalizationsPerUniqueEmail.length; i++) {
      for (let j = 0; j < personalizationsPerUniqueEmail[i].length; j++) {
        pass1Chunks[j] = pass1Chunks[j] || [];
        pass1Chunks[j].push(personalizationsPerUniqueEmail[i][j]);
      }
    }

    let chunks: OrgEmailCompletePersonalization[][] = [];

    pass1Chunks.forEach(pass1Chunk => {
      const innerChunks = _.chunk(pass1Chunk, 500);
      innerChunks.forEach(innerChunk => {
        chunks.push(innerChunk);
      });
    });

    //End incredibly ugly stuff...
    try {
      await Promise.all(
        chunks.map(chunk => {
          return sendGrid.sendMultiple({
            replyTo:
              p.replyToEmailAddress?.replace(/\s/g, "") ??
              orgSettings.defaultReplyToEmailAddress?.replace(/\s/g, "") ??
              "noreply@olliesports.com",
            from: `${p.replyToName ?? org.name} <noreply@olliesports.com>`,
            templateId: "d-ea9d6b33534f418c85072d42d9b5dc0e",
            personalizations: chunk,
            mailSettings: {
              bypassListManagement: {
                enable: true
              }
            }
          });
        })
      );
      olliePipe.emitEvent({
        type: `analytic-org-email-sent`,
        payload: { orgId: p.orgId, emailCount: emailDataFiltered.length }
      });
    } catch (e) {
      olliePipe.emitEvent({
        type: "error-org-event-email",
        payload: {
          orgId: p.orgId,
          error: e
        }
      });
      throw e;
    }
  }
}

export function sanitizeEmailMessageByRemovingHTMLElements(text: string) {
  return text.replace(/<\/?[^>]*?>/g, "");
}

// i18n certified - complete
